import React from 'react';
import {
  AccessibilityAuditComponentResultsSection,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Card Group"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Modules"
          tierThree="Card Group"
        />
        <PageNavigation
          links={[
            'Behavior',
            'Collapse and Expand',
            'Architecture',
            'Usage',
            'Related',
            'Accessibility',
            'Platform Support',
          ]}
        />
        <Section title="Behavior">
          <Paragraph>
            The card group enables for one or multiple related data cards to be
            collapsed for space or expanded to display more information with a
            single click or tap.
          </Paragraph>

          <Paragraph>
            Card groups are aware of user selections. If a data card has been
            changed to its collapsed or expanded view state, those cards will
            display that way when the card group is expanded. Because these
            preferences could quickly complicate the interface, be intentional
            when determining whether or not a card group should be collapsed by
            default.
          </Paragraph>

          <Paragraph>
            In some occasions, there may be quite a few data cards collected
            within a card group. In these situations, when the card group is
            expanded, an optional more/less button can be used to show or hide
            additional data cards, including any that may be empty.
          </Paragraph>
        </Section>

        <Section title="Collapse and Expand">
          <Paragraph>
            The card group is made up of many fixed parts that enable
            flexibility across sports, interfaces and platforms. Card groups can
            be viewed in the <strong>collapsed</strong> and{' '}
            <strong>expanded</strong> states.
          </Paragraph>
          <SectionSubhead>Collapsed</SectionSubhead>
          <Paragraph>
            In the <strong>collapsed</strong> state, the card group will always
            display the title and count of the total number of child data cards
            within it.
          </Paragraph>
          <SectionSubhead>Expanded</SectionSubhead>
          <Paragraph>
            When in the <strong>expanded</strong> state, the card group displays
            some or all child data cards within it, in their pre-determined
            state. Empty cards will always be hidden behind the more/less
            button.
          </Paragraph>
        </Section>

        <Section title="Architecture">
          <Paragraph>
            The card group is made up of many fixed parts that enable
            flexibility across sports, interfaces, and platforms.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Layout</SectionSubhead>
          <Paragraph>
            The card group is meant for use only inside of themed parent
            modules. Use them individually or as a collection of data.
          </Paragraph>
          <DontDo
            dontText="use card groups to collect cards in isolated layouts."
            doText="create content rich experiences within modules."
            imgFilename="cardgroup-modules"
          />

          <Paragraph>
            Organize information with card groups as they are relevant. Be
            decisive when content within a single card group may be better
            suited for two or more.
          </Paragraph>
          <DontDo
            dontText="nest card groups within card groups."
            doText="create new card groups when the collected content becomes too broad."
            imgFilename="cardgroup-nesting"
          />

          <SectionSubhead>Titles</SectionSubhead>
          <Paragraph>
            The card group titles should be as succinct as possible while
            remaining explanatory of all data cards included. Ideally these
            titles should be common or significant to the sport.
          </Paragraph>
          <DontDo
            dontText="be overly verbose when describing a collection of data cards."
            doText="describe grouped data cards intentionally using common language."
            imgFilename="cardgroup-titles"
          />
        </Section>

        <Section title="Related">
          <h3 className="uni-margin--one--bottom">
            <Link href="../../data-card/design">Data Card</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../design#Choosing%20a%20Visualization">
              Data Visualizations
            </Link>
          </h3>

          <h3 className="uni-margin--one--bottom">
            <Link href="../../design">Module System</Link>
          </h3>
          <h3>
            <Link href="../../../../guidelines/accessibility/design">
              Accessibility
            </Link>
          </h3>
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Modules / Card Group" />

        <Section title="Platform Support">
          <Paragraph>
            The card group is available for React and React Native.
          </Paragraph>
        </Section>
      </PageWithSubNav>
    );
  }
}
